<template>
  <div v-if="isReady" class="onboarding-review-admin admin-onboarding-review">
    <vs-card v-if="onboardDetail && !['Application in progress', 'Awaiting approval'].includes(onboardDetail.status)">
      <div slot="header" class="flex items-center" :class="changeStatusColor(onboardDetail.status)">
        <feather-icon icon="ClockIcon" v-if="onboardDetail.status =='Waiting for till'" />
        <feather-icon icon="ThumbsUpIcon" v-if="onboardDetail.status == 'Completed' || onboardDetail.status=='Approved'" />
        <feather-icon icon="AlertCircleIcon" v-if="onboardDetail.status == 'Rejected'" />
        <feather-icon icon="ClockIcon" v-if="onboardDetail.status == 'Info Requested'" />
        <feather-icon icon="ThumbsUpIcon" v-if="onboardDetail.status == 'Resubmitted'" />
        <feather-icon icon="ClockIcon" v-if="onboardDetail.status == 'Waiting on IDV'" />
        <feather-icon icon="AlertCircleIcon" v-if="onboardDetail.status == 'IDV Failed'" />
        <feather-icon icon="AlertCircleIcon" v-if="['Error sending to till', 'Error provisioning account'].includes(onboardDetail.status)" />
        <h5 class="lg:text-xl md:text-lg text-sm">
          Application {{ onboardDetail.status === "Rejected" ? "" : "approved -" }} {{ ["completed", "Awaiting activation"].includes(onboardDetail.status.toLowerCase()) ? "account creation complete" : onboardDetail.status.toLowerCase() }}
        </h5>
      </div>

      <div class="pt-6" v-if="onboardDetail && onboardDetailStatusComputed == 'Awaiting activation' && onboardDetail.hasOwnProperty('partnerDetail') && onboardDetail.partnerDetail.accountStatus === 'Inactive'">
        <div class="flex text-base px-16">
          <div>Merchant account is awaiting activation.</div>
          <div class="ml-auto">
            <vs-button flat v-round @click="goToMerchant">Go to Merchant</vs-button>
          </div>
        </div>
      </div>
    </vs-card>

    <vs-row id="profile-page" class="mt-5">
      <!-- Business Information -->
      <vx-card class="accordin-card">
        <div class="vx-card__body bsv p-0">
          <vs-collapse accordion>
            <vs-collapse-item ref="businessInfoCollapse" :open="isAwaitingForApproval">
              <div slot="header">
                <div class="flex items-center">
                  <div class="icon-wrapper">
                    <img class="icon-control" :src=home alt="home">
                  </div>
                  <h3 class="text-left card-title font-normal lg:text-2xl md:text-xl text-lg">Business information</h3>
                  <div class="ml-auto"></div>
                </div>
              </div>

              <vs-row>
                <div class="w-full" v-if="checkResubmitStatus">
                  <div class="flex mt-5 ml-6 mr-6 mb-10 md:mb-16 onboard-comment" v-if="showComments('Business information')">
                    <span class="material-icons" style="color:#9FC2FB">notes</span>
                    <p class="px-8">{{showComments('Business information')}}</p>
                    <edit-2-icon size="1.2x" class="ml-auto inline mb-0" @click="editComments('Business information')" v-if="!isSaved && !informationSaved"></edit-2-icon>
                    <vs-button v-else flat v-round @click="deleteComments('Business information')" class="mt-1 medium">Clear comment</vs-button>
                  </div>
                  <div class="w-full text-right" v-else>
                    <vs-button flat v-round @click="showResubmission('Business information')" class="only-border-btn mt-3 medium mb-5">
                      <plus-icon size="1.5x" class="button-icon"></plus-icon>Resubmission comment
                    </vs-button>
                  </div>
                </div>
                <!-- General Information -->
                <div class="w-full mb-6">
                  <div class="flex mb-2 items-center ml-1">
                    <div class="flex justify-center items-center" style="max-width:20px;">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0777 1.07709C11.4032 0.751649 11.9308 0.751649 12.2562 1.07709L15.5896 4.41042C15.915 4.73586 15.915 5.26349 15.5896 5.58893L6.42291 14.7556C6.26663 14.9119 6.05467 14.9997 5.83366 14.9997H2.50033C2.04009 14.9997 1.66699 14.6266 1.66699 14.1663V10.833C1.66699 10.612 1.75479 10.4 1.91107 10.2438L11.0777 1.07709ZM3.33366 11.1782V13.333H5.48848L13.8218 4.99967L11.667 2.84485L3.33366 11.1782Z" fill="#BDBDBD"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.66699 18.3333C1.66699 17.8731 2.04009 17.5 2.50033 17.5H17.5003C17.9606 17.5 18.3337 17.8731 18.3337 18.3333C18.3337 18.7936 17.9606 19.1667 17.5003 19.1667H2.50033C2.04009 19.1667 1.66699 18.7936 1.66699 18.3333Z" fill="#BDBDBD"/>
                    </svg></div>
                    <h4 class="text-xl mb-0 font-normal ml-5">General information</h4>
                  </div>
                  <hr class="line-hr mb-8 md:mb-10 w-full ml-1">

                  <GeneralInformation
                    :generalInfo="onboardDetail.businessInfo"
                    :userId="onboardDetail.userId"
                    :showEditButton="showEditButton"
                    :resubmitChanges="resubmitChanges"
                    :status="onboardDetailStatusComputed"
                    @reloadSaveData="getDataAfterGeneralSave($event)"
                  />
                </div>
                <div class="onboarding-form" v-if="defaultCreditLimit">
                  <div class="flex mb-8 flex-wrap">
                    <div class="input-grp">
                      <label class="vs-input--label w-full review-label">Pre-assigned credit limit <a class="ml-16 cursor-pointer" @click="revertToDefaultCreditLimit" v-if="displayCreditLimitRevert">Revert</a></label>
                      <money name="creditLimit" v-model="creditLimit" v-bind="creditLimitBind" class="w-full currency-input" />
                    </div>
                  </div>
                </div>
                <div class="w-full mb-8" v-if="onboardDetail.onboardingCustomFields && onboardDetail.onboardingCustomFields.length">

                  <div class="flex mb-2 items-center ml-1">
                    <div class="flex justify-center items-center" style="max-width:20px;">
                      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0774 1.78224C11.4029 1.4568 11.9305 1.4568 12.2559 1.78224L15.5893 5.11557C15.9147 5.44101 15.9147 5.96865 15.5893 6.29408L6.42259 15.4608C6.26631 15.617 6.05435 15.7048 5.83334 15.7048H2.50001C2.03977 15.7048 1.66667 15.3317 1.66667 14.8715V11.5382C1.66667 11.3171 1.75447 11.1052 1.91075 10.9489L11.0774 1.78224ZM3.33334 11.8833V14.0382H5.48816L13.8215 5.70483L11.6667 3.55001L3.33334 11.8833Z" fill="#BDBDBD"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.66667 19.0382C1.66667 18.5779 2.03977 18.2048 2.50001 18.2048H17.5C17.9602 18.2048 18.3333 18.5779 18.3333 19.0382C18.3333 19.4984 17.9602 19.8715 17.5 19.8715H2.50001C2.03977 19.8715 1.66667 19.4984 1.66667 19.0382Z" fill="#BDBDBD"/>
                      </svg>
                    </div>

                    <h3 class="text-xl fs-control mb-0 font-normal ml-5">
                      Business profile
                    </h3>
                  </div>
                  <vs-row>
                    <div class="w-full">
                      <BusinessProfile
                        :onboardingCustomFields="onboardDetail.onboardingCustomFields"
                        :userId="onboardDetail.userId"
                        :showEditButton="showEditButton"
                        :status="onboardDetail.status"
                        :resubmitChanges="resubmitChanges"
                        :message="onboardDetail.onboardingRequest && onboardDetail.onboardingRequest.onboardingProfileMessage ? onboardDetail.onboardingRequest.onboardingProfileMessage :  null"
                      />
                    </div>
                  </vs-row>
                </div>

                <!-- contact details -->
                <div class="w-full mb-8">
                  <div class="flex mb-2 items-center ml-1">
                    <div class="flex justify-center items-center" style="max-width:20px;">
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0774 1.78224C11.4029 1.4568 11.9305 1.4568 12.2559 1.78224L15.5893 5.11557C15.9147 5.44101 15.9147 5.96865 15.5893 6.29408L6.42259 15.4608C6.26631 15.617 6.05435 15.7048 5.83334 15.7048H2.50001C2.03977 15.7048 1.66667 15.3317 1.66667 14.8715V11.5382C1.66667 11.3171 1.75447 11.1052 1.91075 10.9489L11.0774 1.78224ZM3.33334 11.8833V14.0382H5.48816L13.8215 5.70483L11.6667 3.55001L3.33334 11.8833Z" fill="#BDBDBD"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.66667 19.0382C1.66667 18.5779 2.03977 18.2048 2.50001 18.2048H17.5C17.9602 18.2048 18.3333 18.5779 18.3333 19.0382C18.3333 19.4984 17.9602 19.8715 17.5 19.8715H2.50001C2.03977 19.8715 1.66667 19.4984 1.66667 19.0382Z" fill="#BDBDBD"/>
                    </svg></div>
                    <h3 class="text-xl mb-0 font-normal ml-5">Contact details</h3>
                  </div>
                  <hr class="line-hr mb-8 md:mb-10 w-full ml-1" />
                  <ContactDetails :contactDetails="onboardDetail.contactDetails" :status="onboardDetailStatusComputed" :resubmitChanges="resubmitChanges" />
                </div>
              </vs-row>
            </vs-collapse-item>
          </vs-collapse>
        </div>
      </vx-card>

      <!-- Business ownership -->
      <vx-card class="accordin-card mt-6" id="businessOwnershipArea">
        <div class="vx-card__body bsv p-0">
          <vs-collapse accordion>
            <vs-collapse-item ref="businessOwnership">
              <div slot="header">
                <div class="flex flex-row items-center">
                  <div class="icon-wrapper"><img class="icon-control" :src="bank" alt="filetext" /></div>
                  <h3 class="text-left card-title font-normal lg:text-2xl md:text-xl text-lg">Business ownership</h3>
                </div>
              </div>
              <vs-row>
                <div class="w-full" v-if="checkResubmitStatus">
                  <div class="flex mt-5 ml-6 mr-6 mb-10 md:mb-16 onboard-comment" v-if="showComments('Business ownership')">
                    <span class="material-icons" style="color:#9FC2FB">notes</span>
                    <p class="px-8">{{showComments('Business ownership')}}</p>
                    <edit-2-icon size="1x" class="ml-auto inline mb-0" @click="editComments('Business ownership')" v-if="!isSaved && !ownershipSaved"></edit-2-icon>
                    <vs-button v-else flat v-round @click="deleteComments('Business ownership')" class="mt-1 medium">Clear comment</vs-button>
                  </div>
                  <div class="w-full text-right" v-else>
                    <vs-button flat v-round @click="showResubmission('Business ownership')" class="only-border-btn mt-3 medium mb-5"><plus-icon size="1.5x" class="button-icon"></plus-icon>Resubmission comment</vs-button>
                  </div>
                </div>
                <div class="w-full">
                  <BusinessEntity
                    ref="businessOwnershipChild"
                    :onboardingRequestId="onboardingId"
                    :entityList="onboardDetail.businessOwnership"
                    :businessOwnershipCustomFields="businessOwnershipCustomFields"
                    :status="onboardDetailStatusComputed"
                    :resubmitChanges="resubmitChanges"
                  />
                </div>
              </vs-row>
            </vs-collapse-item>
          </vs-collapse>
        </div>
      </vx-card>

      <!-- Bank Account -->
      <vx-card class="accordin-card mt-4">
        <div class="vx-card__body bsv p-0">
          <vs-collapse accordion>
            <vs-collapse-item ref="bankCollapse" :open="isAwaitingForApproval">
              <div slot="header">
                <div class="flex items-center">
                  <div class="icon-wrapper"><img class="icon-control" :src="bank" alt="bank" /></div>
                  <h3 class="text-left card-title font-normal lg:text-2xl md:text-xl text-lg">Bank Account</h3>
                </div>
              </div>
              <vs-row>
                <div class="w-full" v-if="checkResubmitStatus">
                  <div class="flex mt-5 ml-6 mr-6 mb-10 md:mb-16 onboard-comment" v-if="showComments('Bank accounts')">
                    <span class="material-icons" style="color:#9FC2FB">notes</span>
                    <p class="px-8">{{showComments('Bank accounts')}}</p>
                    <edit-2-icon size="1.2x" class="ml-auto inline mb-0" @click="editComments('Bank accounts')" v-if="!isSaved && !bankSaved"></edit-2-icon>
                    <vs-button v-else flat v-round @click="deleteComments('Bank accounts')" class="mt-1 medium">Clear comment</vs-button>
                  </div>
                  <div class="w-full text-right" v-else>
                    <vs-button flat v-round @click="showResubmission('Bank accounts')" class="only-border-btn mt-3 medium mb-5"><plus-icon size="1.5x" class="button-icon"></plus-icon>Resubmission comment</vs-button>
                  </div>
                </div>
                <div class="w-full">
                  <BankAccounts
                    :bankAccount="onboardDetail.bankAccount"
                    :onboardingRequestId="onboardingId"
                    :showEditButton="showEditButton"
                    :entityList="onboardDetail.businessOwnership"
                    @updateBankAccounts="bankUpdateDelete($event)"
                    :resubmitChanges="resubmitChanges"
                    :applicationStatus="onboardDetailStatusComputed"
                  />
                </div>
              </vs-row>
            </vs-collapse-item>
          </vs-collapse>
        </div>
      </vx-card>

      <!-- Product Selection -->
      <vx-card class="accordin-card mt-4">
        <div class="vx-card__body bsv p-0">
          <vs-collapse accordion>
            <vs-collapse-item ref="templateCollapse" :open="isAwaitingForApproval">
              <div class="w-full" v-if="checkResubmitStatus">
                <div class="flex mt-5 ml-6 mr-6 mb-10 md:mb-16 onboard-comment" v-if="showComments('Product selection')">
                <span class="material-icons" style="color:#9FC2FB">notes</span>
                  <p class="px-8">{{showComments('Product selection')}}</p>
                  <edit-2-icon size="1.2x" class="ml-auto inline mb-0" @click="editComments('Product selection')" v-if="!isSaved && !productSaved"></edit-2-icon>
                  <vs-button v-else flat v-round @click="deleteComments('Product selection')" class="mt-1 medium">Clear comment</vs-button>
                </div>
                <div  class="w-full text-right" v-else>
                  <vs-button flat v-round @click="showResubmission('Product selection')" class="only-border-btn mt-3 medium mb-5"><plus-icon size="1.5x" class="button-icon"></plus-icon>Resubmission comment</vs-button>
                </div>
              </div>
              <div slot="header">
                <div class="flex items-center">
                  <div class="icon-wrapper">
                    <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.3189 2.15702C14.7331 1.88776 15.2671 1.88776 15.6813 2.15702L28.1813 10.282C28.5361 10.5126 28.7501 10.907 28.7501 11.3301V20.0801C28.7501 20.5032 28.5361 20.8976 28.1813 21.1281L15.6813 29.2531C15.2671 29.5224 14.7331 29.5224 14.3189 29.2531L1.81887 21.1281C1.46413 20.8976 1.25011 20.5032 1.25011 20.0801V11.3301C1.25011 10.907 1.46413 10.5126 1.81887 10.282L14.3189 2.15702ZM3.75011 12.0084V19.4017L15.0001 26.7142L26.2501 19.4017V12.0084L15.0001 4.69594L3.75011 12.0084Z" fill="#00CFFF"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0001 18.8301C15.6905 18.8301 16.2501 19.3897 16.2501 20.0801V28.2051C16.2501 28.8954 15.6905 29.4551 15.0001 29.4551C14.3098 29.4551 13.7501 28.8954 13.7501 28.2051V20.0801C13.7501 19.3897 14.3098 18.8301 15.0001 18.8301Z" fill="#00CFFF"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.47607 10.6132C1.87196 10.0477 2.65137 9.91015 3.21694 10.306L15.0001 18.5543L26.7833 10.306C27.3488 9.91015 28.1283 10.0477 28.5241 10.6132C28.92 11.1788 28.7825 11.9582 28.2169 12.3541L15.7169 21.1041C15.2865 21.4054 14.7137 21.4054 14.2833 21.1041L1.78328 12.3541C1.21772 11.9582 1.08017 11.1788 1.47607 10.6132Z" fill="#00CFFF"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2833 10.306C14.7137 10.0048 15.2865 10.0048 15.7169 10.306L28.2169 19.056C28.7825 19.4519 28.92 20.2313 28.5241 20.7969C28.1283 21.3625 27.3488 21.5 26.7833 21.1041L15.0001 12.8559L3.21694 21.1041C2.65137 21.5 1.87196 21.3625 1.47607 20.7969C1.08017 20.2313 1.21772 19.4519 1.78328 19.056L14.2833 10.306Z" fill="#00CFFF"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0001 1.95508C15.6905 1.95508 16.2501 2.51472 16.2501 3.20508V11.3301C16.2501 12.0204 15.6905 12.5801 15.0001 12.5801C14.3098 12.5801 13.7501 12.0204 13.7501 11.3301V3.20508C13.7501 2.51472 14.3098 1.95508 15.0001 1.95508Z" fill="#00CFFF"/>
                    </svg>
                  </div>
                  <h3 class="text-left card-title font-normal lg:text-2xl md:text-xl text-lg">
                    Product Selection
                  </h3>
                </div>
              </div>

              <div>
                <ProductSelection
                  :product="onboardDetail.productSelection"
                  :userId="onboardDetail.userId"
                  :showEditButton="showEditButton"
                  :status="onboardDetail.status"
                  :resubmitChanges="resubmitChanges"
                  :banks="onboardDetail.bankAccount"
                ></ProductSelection>
              </div>
            </vs-collapse-item>
          </vs-collapse>
        </div>
      </vx-card>

      <!-- Users section -->
      <vx-card class="accordin-card mt-4 mb-5 terms-card">
        <div class="vx-card__body bsv p-0">
          <vs-collapse accordion>
            <vs-collapse-item open>
              <div slot="header">
                <div class="flex flex-row items-center">
                  <img class="icon-control" :src="home" alt="home" />
                  <h3 class="text-left card-title font-normal lg:text-2xl md:text-xl text-lg pl-4">Users</h3>
                </div>
              </div>

              <Users :users="onboardDetail.users" ref="user" :user-validations="userValidations" />
            </vs-collapse-item>
          </vs-collapse>
        </div>
      </vx-card>

      <div class="flex items-center w-full" style="margin-top:48px;">
        <div class="side-lines"></div>
        <span class="font-normal px-5 lg:text-2xl md:text-xl text-lg" style="color:#000000;">COMPLIANCE</span>
        <div class="side-lines"></div>
      </div>

      <!-- Supporting documents -->
      <vx-card class="accordin-card mt-4">
        <div class="vx-card__body bsv p-0">
          <vs-collapse accordion>
            <vs-collapse-item ref="document" :open="isAwaitingForApproval">
              <div slot="header">
                <div class="flex items-center">
                  <div class="icon-wrapper">
                  <svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.95856 1.88239C1.57232 1.26863 2.40475 0.923828 3.27273 0.923828H12C12.2893 0.923828 12.5668 1.03876 12.7714 1.24335L19.3168 7.7888C19.5214 7.99339 19.6364 8.27086 19.6364 8.56019V21.6511C19.6364 22.5191 19.2916 23.3515 18.6778 23.9653C18.0641 24.579 17.2316 24.9238 16.3636 24.9238H3.27273C2.40475 24.9238 1.57231 24.579 0.95856 23.9653C0.344804 23.3515 0 22.5191 0 21.6511V4.19656C0 3.32857 0.344804 2.49614 0.95856 1.88239ZM3.27273 3.10565C2.9834 3.10565 2.70592 3.22058 2.50134 3.42517C2.29675 3.62975 2.18182 3.90723 2.18182 4.19656V21.6511C2.18182 21.9404 2.29675 22.2179 2.50134 22.4225C2.70592 22.6271 2.9834 22.742 3.27273 22.742H16.3636C16.653 22.742 16.9304 22.6271 17.135 22.4225C17.3396 22.2179 17.4545 21.9404 17.4545 21.6511V9.01206L11.5481 3.10565H3.27273Z" fill="#00CFFF"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12 0.923828C12.6025 0.923828 13.0909 1.41224 13.0909 2.01474V7.46928H18.5455C19.1479 7.46928 19.6364 7.9577 19.6364 8.56019C19.6364 9.16268 19.1479 9.6511 18.5455 9.6511H12C11.3975 9.6511 10.9091 9.16268 10.9091 8.56019V2.01474C10.9091 1.41224 11.3975 0.923828 12 0.923828Z" fill="#00CFFF"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.36364 14.0147C4.36364 13.4122 4.85205 12.9238 5.45455 12.9238H14.1818C14.7843 12.9238 15.2727 13.4122 15.2727 14.0147C15.2727 14.6172 14.7843 15.1056 14.1818 15.1056H5.45455C4.85205 15.1056 4.36364 14.6172 4.36364 14.0147Z" fill="#00CFFF"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.36364 18.3784C4.36364 17.7759 4.85205 17.2875 5.45455 17.2875H14.1818C14.7843 17.2875 15.2727 17.7759 15.2727 18.3784C15.2727 18.9809 14.7843 19.4693 14.1818 19.4693H5.45455C4.85205 19.4693 4.36364 18.9809 4.36364 18.3784Z" fill="#00CFFF"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.36364 9.6511C4.36364 9.04861 4.85205 8.56019 5.45455 8.56019H7.63636C8.23886 8.56019 8.72727 9.04861 8.72727 9.6511C8.72727 10.2536 8.23886 10.742 7.63636 10.742H5.45455C4.85205 10.742 4.36364 10.2536 4.36364 9.6511Z" fill="#00CFFF"/>
                  </svg>
                  </div>
                  <h3 class="text-left card-title font-normal lg:text-2xl md:text-xl text-lg">
                    Supporting information
                  </h3>
                </div>
              </div>

              <div>
                <SupportingDocument
                  :document="onboardDetail.supportingDocuments"
                  :onboardingRequestId="onboardDetail.onboardingRequestId"
                  :showEditButton="showEditButton"
                  @handleCardHeight="handleCardHeight('document')"
                  @handleSupportingDocumentAdded="handleSupportingDocumentAdded"
                  :applicationStatus="onboardDetailStatusComputed"
                ></SupportingDocument>
              </div>
            </vs-collapse-item>
          </vs-collapse>
        </div>
      </vx-card>

      <!-- ID Verification -->
      <vx-card class="accordin-card mt-4">
        <div class="vx-card__body bsv p-0">
          <vs-collapse accordion>
            <vs-collapse-item ref="templateCollapse" :open="isAwaitingForApproval">
              <div slot="header">
                <div class="flex items-center">
                  <div class="icon-wrapper">
                    <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M23.3332 25.4238V23.0905C23.3332 21.8528 22.8415 20.6658 21.9663 19.7907C21.0912 18.9155 19.9042 18.4238 18.6665 18.4238H9.33317C8.09549 18.4238 6.90851 18.9155 6.03334 19.7907C5.15817 20.6658 4.6665 21.8528 4.6665 23.0905V25.4238" stroke="#00CFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M14.0002 13.7572C16.5775 13.7572 18.6668 11.6678 18.6668 9.0905C18.6668 6.51317 16.5775 4.42383 14.0002 4.42383C11.4228 4.42383 9.3335 6.51317 9.3335 9.0905C9.3335 11.6678 11.4228 13.7572 14.0002 13.7572Z" stroke="#00CFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>

                  <h3 class="text-left card-title font-normal lg:text-2xl md:text-xl text-lg">
                    ID Verification
                  </h3>
                </div>
              </div>

              <div>
                <Idverification :IdDetail="onboardDetail.businessOwnership" :applicationStatus="onboardDetail.status" />
              </div>
            </vs-collapse-item>
          </vs-collapse>
        </div>
      </vx-card>

      <!-- Audit Log -->
      <vx-card class="accordin-card mt-4">
        <div class="vx-card__body bsv p-0">
          <vs-collapse accordion>
            <vs-collapse-item ref="auditLogCollapse">
              <div slot="header">
                <div class="flex items-center">
                  <div class="icon-wrapper">
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8 6.92383H21" stroke="#00CFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M8 12.9238H21" stroke="#00CFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M8 18.9238H21" stroke="#00CFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M3 6.92383H3.01" stroke="#00CFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M3 12.9238H3.01" stroke="#00CFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M3 18.9238H3.01" stroke="#00CFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>

                  <h3 class="text-left card-title font-normal lg:text-2xl md:text-xl text-lg">
                    Audit Log
                  </h3>
                </div>
              </div>

              <vs-row>
                <div>
                  <AuditLog :logs="onboardDetail.auditLogs" @handleCardHeight="handleCardHeight('auditLog')"/>
                </div>
              </vs-row>
            </vs-collapse-item>
          </vs-collapse>
        </div>
      </vx-card>

      <!-- Buttons -->
      <div class="mt-10 flex flex-wrap w-full justify-end items-center">
        <vs-button v-if="checkResubmitStatus" size="large" v-round class="lg:mx-6 md:mx-4 mx-2 only-border-btn" @click="sendBackForm('save')">Save</vs-button>
        <vs-button
          size="large"
          v-round
          :disabled="[
            'Pending',
            'Rejected',
            'Awaiting activation',
            'Approved',
            'Completed',
            'Error provisioning account',
            'Provisioning error',
            'Application in progress',
            'Error sending to till',
            'Waiting for till',
            'Can\'t match OB',
            'Provisioning error',
            ].includes(onboardDetailStatusComputed)"
          class="only-border-btn"
          @click="activeReject()"
        >
          Reject
        </vs-button>

        <vs-button v-if="resubmission.length > 0 && checkResubmitStatus" size="large" v-round class="lg:mx-6 md:mx-4 mx-2" @click="sendBackForm('send')">Send back</vs-button>
        <vs-button v-else size="large" v-round class="lg:mx-6 md:mx-4 mx-2" :disabled="checkApproveStatus()" @click="activeApprove()">Approve</vs-button>
        <a class="mid-blue" @click="gotoList()">Cancel</a>
      </div>
    </vs-row>

    <!--for approve -->
    <vs-popup class="holamundo beneficial-popup" title="Approve application" :active.sync="showApproveModel">
      <p>You are approving the merchant application. Once this application is approved you will no longer be able to edit the form or perform any compliance actions.</p>

      <div class="flex mt-3">
        <div class="w-full">
          <label>Please ensure you have done the following :</label>
          <ul class="mt-3 approve-application">
            <li><p>Uploaded the Equifax beneficial ownership report</p></li>
            <li><p>Uploaded the Equifax credit report</p></li>
            <li><p>Send and confirm completion of ID verification requests for all beneficial owners and authorized signatures.</p></li>
          </ul>
        </div>
      </div>

      <div class="flex justify-end items-center mt-8">
        <vs-button size="large" v-round @click="Approve()" class="mr-8">Approve</vs-button>
        <a @click="approveCloseModel()">Cancel</a>
      </div>
    </vs-popup>

    <!-- for reject -->
    <vs-popup class="beneficial-popup delete-pop-up" title="Reject Application " :active.sync="showRejectModel">
      <p>You are rejecting this merchant's application. Please note you can't undo this action.</p>

      <div class="flex mt-3">
        <div class="w-full">
          <label > Reason for rejection<span class="mid-blue">*</span></label>
          <vs-textarea class="mt-2 popup-textarea w-1/2" v-validate="'required'" v-model="rejectNote"></vs-textarea>
          <span v-show="errors.has(`supportingdoc`)" class="text-danger text-sm">{{ changeMessage(errors.first(`supportingdoc`), `supportingdoc`, "title") }}</span>
        </div>
      </div>

      <div class="flex justify-end items-center">
        <vs-button size="large" v-round @click="Reject()" class="border-only-btn mr-8">Reject</vs-button>
        <a @click="rejectCloseModel()" class="mid-blue">Cancel</a>
      </div>
    </vs-popup>

    <!---resubmission content-->
    <vs-popup class="holamundo resubmit-popup" :title="cardTitle" :active.sync="showResubmitModel">
      <p class="mb-4">
        Please detail reason for return and any instructions for the merchant:
      </p>

      <div class="flex mt-10 pt-3">
        <div class="w-full">
          <label class="vs-input--label p-0 w-full text-base text-dark-blue pb-1 block">Note to merchant <span class="mid-blue">*</span></label>
          <vs-textarea
            v-model="merchantnote"
            name="merchantnote"
            v-validate="'required'"
            data-vv-as="merchant note"
            counter="1000"
            maxlength="1000"
            id="merchant-note-block"
            class="resubmit-textarea"
            data-vv-scope="commentNote"
            @input="auto_grow('merchant-note-block')"
          ></vs-textarea>
          <span v-show="errors.has(`commentNote.merchantnote`)" class="text-danger text-sm">{{
            errors.first(`commentNote.merchantnote`)
          }}</span>
        </div>
      </div>

      <div class="flex justify-end items-center mt-16">
        <vs-button size="large" v-round class="only-border-btn mr-8" @click="deleteComments(activeSection)" v-if="commentEditMode">Delete</vs-button>
        <vs-button size="large" v-round class="mr-8" @click="addComments">{{ commentEditMode ? "Save" : "Add" }}</vs-button>
        <a @click="hideResubmission">Cancel</a>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Money } from "v-money";

import GeneralInformation from "./businessInformation/GeneralInformation.vue";
import ContactDetails from "./businessInformation/ContactDetails.vue";
import BusinessProfile from "./businessProfile/BusinessProfile.vue";
import BankAccounts from "./bankAccount/bankAccounts.vue";
import ProductSelection from "./product/ProductSelection.vue";
import SupportingDocument from "./supportingDocument/SupportingDocument.vue";
import Approve from "./action/approve.vue";
import Reject from "./action/reject.vue";
import Idverification from "./Idverification/Idverification.vue";
import AuditLog from "./auditLog/AuditLog.vue";
import moment from "moment";
import {EventBus} from "../../../event-bus";
import S3FileGenerator from "../../components/S3FileGenerator";
import BusinessEntity from "./businessEntity/BusinessEntity";
import Users from "./users/index";

import {PlusIcon, Edit2Icon} from "vue-feather-icons";

const home=require("@/assets/images/edit-blue.svg");
const bank=require("@/assets/images/bank-blue.png");
const filetext=require("@/assets/images/file-text.png");
const product=require("@/assets/images/product.png");
const list=require("@/assets/images/list.svg");

export default {
  name: "index",
  components: {
    GeneralInformation,
    ContactDetails,
    BusinessProfile,
    BankAccounts,
    ProductSelection,
    SupportingDocument,
    Approve,
    Reject,
    Idverification,
    AuditLog,
    S3FileGenerator,
    BusinessEntity,
    PlusIcon,
    Edit2Icon,
    Users,
    Money
  },
  data() {
    return {
      isReady: false,
      isAwaitingForApproval: false,
      onboardingId: "",
      onboardDetail: {},
      showEditButton: true,
      showApproveModel: false,
      showRejectModel: false,
      banner: {
        color: "",
        message: "",
        show: false,
      },
      rejectNote: "",
      home: home,
      bank: bank,
      filetext: filetext,
      product:product,
      list: list,
      businessOwnershipCustomFields: [],
      IdDetail: [],
      showResubmitModel: false,
      activeSection: "",
      merchantnote: "",
      cardTitle: "",
      resubmission: [],
      commentEditMode: false,
      isSaved: false,
      informationSaved: false,
      ownershipSaved: false,
      bankSaved: false,
      productSaved: false,
      resubmitChanges: [],
      userValidations: [],
      creditLimit: null,
      creditLimitBind: {
        prefix: "$ ",
        precision: 2,
      },
    };
  },

  mounted() {
    this.onboardingId = this.$route.params.onboardingId;
    if (this.$refs.businessInfoCollapse) {
      this.$refs.businessInfoCollapse.maxHeight = "none !important";
    }
  },

  watch: {
    primaryContactPerson (val) {
      if (this.$refs.businessInfoCollapse) {
        this.$refs.businessInfoCollapse.maxHeight = "none !important";
      }
    },
    bankAccount(val) {
      if (this.$refs.bankCollapse) {
        this.$refs.bankCollapse.maxHeight = "none !important";
      }
    },
    document(val) {
      if (this.$refs.document) {
        this.$refs.document.maxHeight = "none !important";
      }
    },
  },

  methods: {
    ...mapActions("onboardingAdmin", ["fetchOnboardDetailWithId", "submitApplication", "statusUpdate", "linkIntroducerToMerchant", "sendBackOnboard"]),
    goToMerchant() {
      this.$router.push({
        name: "partners-edit",
        params: {
          id: this.onboardDetail.partnerDetail._id
        }
      })
    },

    revertToDefaultCreditLimit() {
      if (this.onboardDetail && this.onboardDetail.onboardingRequest && this.onboardDetail.onboardingRequest.creditLimit) {
        this.creditLimit = this.addZeroes(this.onboardDetail.onboardingRequest.creditLimit);
      }
    },

    addZeroes(num) {
      num = String(num);
      return Number(num).toLocaleString(undefined, { minimumFractionDigits: 2 });
    },

    checkApproveStatus() {
      let checkStatus = [
        "Pending",
        "Rejected",
        "Awaiting activation",
        "Approved",
        "Completed",
        "Error provisioning account",
        "Provisioning error",
        "Application in progress",
        "Error sending to till",
        "Waiting for till",
        "Can\'t match OB",
        "Provisioning error",
        "IDV Failed",
      ].includes(this.onboardDetailStatusComputed);
      return checkStatus || !this.hasUser;
    },

    changeStatusColor (status) {
      let bgClass = "";
      if (status == "Application in progress") {
        bgClass = "success-border";
      } else if (status == "Awaiting approval") {
        bgClass = "success";
      } else if (["Awaiting activation", "Completed", "IDV Complete"].includes(status)) {
        bgClass = "success";
      } else if (status == "Approved") {
        bgClass = "approved";
      } else if (status == "Resubmitted") {
        bgClass = "resubmit";
      } else if (status == "Waiting for till" || status  == "Waiting on IDV") {
        bgClass = "info";
      } else if (status == "Rejected" || status == 'IDV Failed') {
        bgClass = "reject-color";
      } else if (status == "Error sending to till" || status == "Provisioning error" || status == "Can't match OB" || status == "Error provisioning account"){
        bgClass = "reject-color";
      } else{
        bgClass = "default-border";
      }
      return bgClass;
    },

    businessInfoUpdateDelete() {
      if(this.$refs.businessInfoCollapse) {
        this.$refs.businessInfoCollapse.maxHeight = "none !important";
      }
    },

    bankUpdateDelete(operation) {
      if (this.$refs.bankCollapse) {
        this.$refs.bankCollapse.maxHeight = "none !important";
      }

      if (operation === "delete") {
        this.$vs.notify({
          title: "Bank",
          text: "Bank account has been deleted",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      } else {
        this.$vs.notify({
          title: "Bank",
          text: "Bank account has been added",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success",
        });
      }
    },

    async getDetail() {
      this.$vs.loading();
      await this.fetchOnboardDetailWithId(this.$route.params.onboardingId).then((result) => {
        this.onboardDetail = result.data.data;
        this.onboardDetail.users = this.onboardDetail && this.onboardDetail.users && this.onboardDetail.users.length ? this.onboardDetail.users : [];
        this.userId = result.data.data.userId;
        this.businessOwnershipCustomFields = result.data.data.onboardingRequest.businessOwnershipFields;
        this.isReady = true;
        this.IdDetail = result.data.data.businessOwnership;

        if (result.data.data && result.data.data.creditLimit) {
          this.creditLimit = this.addZeroes(result.data.data.creditLimit);
        } else if (result.data.data.onboardingRequest && result.data.data.onboardingRequest.creditLimit) {
          this.creditLimit = this.addZeroes(result.data.data.onboardingRequest.creditLimit);
        }

        if (["Completed", "Awaiting approval", "Waiting for till"].includes(result.data.data.status)) {
          this.showEditButton = false;
          this.isAwaitingForApproval = true;
        }

        this.resubmission = result.data.data.resubmission || this.resubmission;
        this.resubmission.map((item) => {
          if (item.name === "Business information") {
            this.informationSaved = true;
          }
          if (item.name === "Business ownership") {
            this.ownershipSaved = true;
          }
          if (item.name === "Bank accounts") {
            this.bankSaved = true;
          }
          if (item.name === "Product selection") {
            this.productSaved = true;
          }

          return item;
        });

        this.resubmitChanges = result.data.data.resubmitChanges || this.resubmitChanges;
        this.$vs.loading.close();
      }).catch((ex) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Bank",
          text: "Unable to obtain onboard information",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      });
    },

    async submitCompleteApplication() {
      const data = { _id: this.onboardingId };
      await this.submitApplication(data).then((result) => {
        if (result.data.data) {
          this.$router.push({ name: "SubmissionApplication" });
        }
      }).catch((ex) => {
        this.$vs.notify({
          subject: "Application",
          text: "Error",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
        this.$vs.loading.close();
      });
    },

    prepareCreditLimit() {
      if (this.creditLimit) {
        let creditLimit = String(this.creditLimit);

        if (creditLimit.includes(",")) {
          creditLimit = creditLimit.split(",").join("").replace(".00", "");
        }

        return creditLimit;
      }

      return null;
    },

    async Approve() {
      const data = {
        status: "Completed",
        onboardingRequestId: this.onboardingId,
        rejectedNote: "",
        resubmission: this.resubmission || [],
        users: this.onboardDetail.users,
        creditLimit: this.prepareCreditLimit()
      };

      this.$vs.loading();

      await this.statusUpdate(data).then((result) => {
        this.showApproveModel = false;
        window.scrollTo(0, 0);
        this.onboardDetail = result.data.data.application;
        this.onboardDetail.auditLogs = result.data.data.application.auditLogs;
        this.$vs.loading.close();
        this.$vs.notify({
          subject: "Application Approved",
          text: "Success",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success",
        });
      }).catch((ex) => {
        this.showApproveModel = false;

        this.$vs.notify({
          title: "Email",
          text: ex.response.data.message || "Error",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });

        if (ex.response.status == 422) {
          this.userValidations = ex.response.data.data;
        } else {
          window.scrollTo(0, 0);
        }

        this.$vs.loading.close();
      })
    },

    async LinkIntroducerToMerchant(merchantId, introducerId) {
      const data = {
        merchantId: merchantId,
        introducerId: introducerId,
      };
      await this.linkIntroducerToMerchant(data).then((result) => {
        this.banner = {
          color: "success",
          message: "Introducer linked to merchant",
          show: true,
        };
      }).catch((err) => {
        this.$vs.notify({
          subject: "Application",
          text: "Error",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
        this.$vs.loading.close();
      });
    },

    async Reject() {
      const data = {
        status: "Rejected",
        onboardingRequestId: this.onboardingId,
        rejectedNote: this.rejectNote,
      };
      await this.statusUpdate(data).then((result) => {
        this.showRejectModel = false;
        window.scrollTo(0, 0);
        this.onboardDetail = result.data.data.application;
        this.onboardDetail.auditLogs = result.data.data.application.auditLogs;
      }).catch((ex) => {
        this.$vs.notify({
          subject: "Application",
          text: "Error",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
        this.$vs.loading.close();
      });
    },

    approveCloseModel() {
      this.showApproveModel = false;
    },

    rejectCloseModel() {
      this.showRejectModel = false;
    },

    async activeApprove() {
      const valid = await this.$refs.user.validate();

      if (!valid) {
        return;
      }

      this.showApproveModel = true;
    },

    activeReject() {
      this.showRejectModel = true;
    },

    gotoList() {
      this.$router.push({ name: "onboarding-admin" });
    },

    changeMessage(data, removeString, addString) {
      return data ? data.replace(removeString, addString) : "";
    },

    moment(date) {
      return moment(date).format("DD MMM YYYY H:m:s A");
    },

    handleCardHeight(card) {
      if (card === "auditLog" && this.$refs.auditLogCollapse) {
        this.$refs.auditLogCollapse.maxHeight = "none !important";
      }
      else if (this.$refs[card]) {
        this.$refs[card].maxHeight = "none !important";
      }
    },

    handleSupportingDocumentAdded(auditLogs) {
      if (auditLogs && auditLogs.length) {
        this.onboardDetail.auditLogs = auditLogs;
      }
    },

    downloadPayload(data) {
      let requestBody = { payload: data.payload };
      let filename = "payload.json";
      let content = JSON.stringify(requestBody);
      let element = document.createElement("a");
      element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(content));
      element.setAttribute("download", filename);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },

    getDataAfterGeneralSave() {
      this.getDetail();
    },

    auto_grow(elem) {
      let element = document.getElementById(elem);
      element.style.height = "5px";
      element.style.height = (element.scrollHeight)+"px";
      this.$refs.cusTextCollapse.maxHeight = "none !important";
    },
    showResubmission(section) {
      this.showResubmitModel = true;
      this.activeSection = section;
      let title = this.commentEditMode ? "Edit comments- " : "Add resubmission comment- ";
      this.cardTitle = title+section;

      if (this.errors.has(`commentNote.merchantnote`)) {
        this.errors.remove(`commentNote.merchantnote`)
      }
    },

    hideResubmission() {
      this.showResubmitModel = false;
      this.commentEditMode = false;
      this.cardTitle = "";
      this.merchantnote = "";
      this.activeSection = "";

      if (this.errors.has(`commentNote.merchantnote`)) {
        this.errors.remove(`commentNote.merchantnote`)
      }
    },

    addComments(){
      this.$validator.validate("commentNote.*").then((result) => {
        if (result) {
          let objIndex = this.resubmission.findIndex((obj) => obj.name === this.activeSection);
          if (objIndex >= 0) {
            this.resubmission[objIndex].comment = this.merchantnote;
          } else {
            let data = {
              name: this.activeSection,
              comment: this.merchantnote,
              resolved: false
            }
            this.resubmission.push(data);
          }
          this.isSaved = false;
          this.hideResubmission();
        }
      });
    },

    editComments(section) {
      this.commentEditMode = true;
      this.showResubmission(section);
      let objIndex = this.resubmission.findIndex((obj) => obj.name === this.activeSection);

      if (objIndex >= 0) {
        this.merchantnote = this.resubmission[objIndex].comment;
      }
    },

    showComments(section) {
      let objData = this.resubmission.find((obj) => obj.name === section);
      if (objData) {
        return objData.comment;
      }
      return false;
    },

    deleteComments(section){
      let objIndex = this.resubmission.findIndex((obj) => obj.name === section);
      if (objIndex >= 0) {
        this.resubmission.splice(objIndex, 1)
      }
      if (section === "Business information") {
        this.informationSaved = false;
      }
      if (section === "Business ownership") {
        this.ownershipSaved = false;
      }
      if (section === "Bank accounts") {
        this.bankSaved = false;
      }
      if (section === "Product selection") {
        this.productSaved = false;
      }
      this.hideResubmission();
    },

    async sendBackForm(status) {
      const valid = await this.$refs.user.validate();

      if (!valid) {
        return;
      }
      let data = "";

      if (status === "send") {
        data = {
          status: "Info Requested",
          onboardingRequestId: this.onboardingId,
          resubmission: this.resubmission,
          resubmitChanges: [],
          terms: {
            autorizedPerson : {
              esignatures: {
                template_id: "",
                status: "not_send",
              },
              name: "",
              position: "",
            },
            autorizedTerms: false,
            generalTerms: false,
            payNowTerms: false,
            productTerms: []
          }
        }
      } else if (status === "save") {
        data = {
          onboardingRequestId:this.onboardingId,
          resubmission:this.resubmission
        }

        if ((!this.resubmission || (this.resubmission && this.resubmission.length == 0)) && this.onboardDetail.status === "Resubmitted") {
          data.status = this.onboardDetail.status;
          data.resubmitChanges = [];
        }
      }

      data.action = status;
      data.users = this.onboardDetail.users;
      data.creditLimit = this.prepareCreditLimit();

      this.$vs.loading();
      await this.sendBackOnboard(data).then((result) => {
        if (result) {
          window.scrollTo(0, 0);
          this.onboardDetail = result.data.data.application;
          this.getDetail();
          this.$vs.loading.close();
          let title = "";

          if (status === "save") {
            title = "Application saved";
          } else {
            title = "Application sent back";
          }
          this.$vs.notify({
            subject: title,
            text: "Success",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",
          });
        }
        this.$vs.loading.close();
      }).catch((ex) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Onboarding application",
          text: ex.data.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      })
    },
  },

  beforeMount() {
    this.getDetail();
  },

  computed: {
    hasUser() {
      return (this.onboardDetail && this.onboardDetail.users && this.onboardDetail.users.length > 0);
    },
    baseUrl() {
      return process.env.VUE_APP_API_URL;
    },
    hasbeneficialDocuments() {
      let beneficialDocuments = this.$lodashHelper.checkObjectKey(this.onboardDetail, "businessInfo.beneficialDocuments", []);
      return beneficialDocuments.length > 0 ? true : false
    },

    verifiedIdList() {
      let idLists = [...this.IdDetail];
      let totalCompanyList = [];
      let totalPersonList = [];

      idLists.forEach((boWoners)=>{
        if (boWoners.entityType == "Company" ) {
          boWoners.beneficialOwners.forEach((boDetail) => {
            totalCompanyList.push(boDetail);
          })
        }

        if (boWoners.entityType == "Individual" ) {
          totalCompanyList.push(boWoners);
        }
      });

      return totalCompanyList;
    },
    checkResubmitStatus() {
      let checkStatus = ["Awaiting approval", "Info Requested", "Resubmitted", "IDV Complete", "Waiting on IDV"].includes(this.onboardDetail.status);
      return checkStatus;
    },
    onboardDetailStatusComputed(){
      return this.$lodashHelper.checkObjectKey(this.onboardDetail,"status","");
    },

    displayCreditLimitRevert() {
      return (this.creditLimit != this.defaultCreditLimit);
    },

    defaultCreditLimit() {
      return this.onboardDetail && this.onboardDetail.onboardingRequest && this.onboardDetail.onboardingRequest.creditLimit ? this.onboardDetail.onboardingRequest.creditLimit : null;
    }
  },
};
</script>

